import React , { useState , useEffect }from 'react';
//import { Alert, Modal, ModalBody, ModalFooter, ModalHeader, Button } from 'reactstrap';
//import { COLORS } from '../../utils/color';
import ErrorSVG from '../alert_svgs/error.svg'
import SuccessSVG from '../alert_svgs/success.svg'
import WarningSVG from '../alert_svgs/warning.svg'
import HelpSVG from '../alert_svgs/help.svg'
import InfoSVG from '../alert_svgs/information.svg'

const AlertTemplate = ({ style, options, message }) => {
    const [is_short_alert, set_is_short_alert] = useState(false)

	useEffect(() => {
		if(message && message.length<=40){
			set_is_short_alert(true)
		}
	}, [message]);

    return(
        <React.Fragment>
            { 
                options.type==='info' && 
                <div id='alert_main_div' style={{backgroundColor:'#FFFFFF',display:'flex' ,color:'black', padding:'15px', margin:'20px', width:'400px', borderRadius:'4px', boxShadow:'0px 4px 16px rgba(0, 0, 0, 0.25)', zIndex:'1000001'}}>
					<div style={{fontSize:'18px',}}><i style={{color:'#0747A6', marginRight:'15px', marginTop:'4px', marginLeft:'4px'}} className='fa fa-info-circle'/></div>
					<div style={{fontSize:'14px',flex:'8', display:'flex', flexDirection:'column'}}>
						<span style={{ fontSize:'16px',fontWeight:'600', marginTop:is_short_alert?'1px':'0px'}}>{is_short_alert?(message?message:''):'Info'}</span>
						{
							is_short_alert?'':<span>{message?message:''}</span>
						}
					</div>
                </div>
            }
            { 
                options.type==='success' && 
                <div id='alert_main_div' style={{backgroundColor:'#CCF4E3',display:'flex' ,color:'black', padding:'15px', margin:'20px', width:'400px', borderRadius:'4px', boxShadow:'0px 4px 16px rgba(0, 0, 0, 0.20)', zIndex:'1000001'}}>
					<div style={{fontSize:'18px',}}><i style={{color:'green', marginRight:'15px', marginTop:'4px', marginLeft:'4px'}} className='fa fa-check-circle'/></div>
					<div style={{fontSize:'14px',flex:'8', display:'flex', flexDirection:'column'}}>
						<span style={{ fontSize:'16px',fontWeight:'600', marginTop:is_short_alert?'1px':'0px'}}>{is_short_alert?(message?message:''):'Success'}</span>
						{
							is_short_alert?'':<span>{message?message:''}</span>
						}
					</div>
                </div>
            }
            { 
                options.type==='error' && 
                <div id='alert_main_div' style={{backgroundColor:'#FFEDEF',display:'flex' ,color:'black', padding:'15px', margin:'20px', width:'400px', borderRadius:'4px', boxShadow:'0px 4px 16px rgba(0, 0, 0, 0.20)', zIndex:'1000001'}}>
                    <div style={{fontSize:'18px',}}><img height='28px' width='28px' src={ErrorSVG} style={{ marginRight:'10px', marginLeft:'0px'}}/></div>
					<div style={{fontSize:'14px',flex:'8', display:'flex', flexDirection:'column'}}>
						<span style={{ fontSize:'16px',fontWeight:'600', marginTop:is_short_alert?'2px':'0px'}}>{is_short_alert?(message?message:''):'Error'}</span>
						{
							is_short_alert?'':<span>{message?message:''}</span>
						}
					</div>
                </div>
            }
            { 
                options.type==='status' && 
                <div id='alert_main_div' style={{backgroundColor:"transparent",marginTop:120,fontSize:"12px",display:'flex' ,color:'black', padding:'15px', margin:'15px', width:'420px', borderRadius:'4px', boxShadow:'0px 4px 16px rgba(0, 0, 0, 0.25)', zIndex:'1000001'}}>
                    <span style={{display:'flex', alignItems:'center', justifyContent:'flex-start'}}>{message?message:"No Message"}</span>
                </div>
            }
        </React.Fragment>
    )
}

AlertTemplate.defaultProps = {
    style : {},
    options: {},
};

export default AlertTemplate;