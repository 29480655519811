import * as Sentry from "@sentry/react";

export class FetchError extends Error {
    constructor(message, url, response, request_id) {
        super(message);
        this.name = 'FetchError';
        this.url = url
        this.response = response;
        this.request_id = request_id
    }
}

export class NetworkError extends Error {
    constructor(message, url, response) {
        super(message);
        this.name = 'NetworkError';
        this.url = url
        this.response = response
    }
}

export class ExceptionEventError extends Error {
    constructor(message, code, response) {
        super(message);
        this.name = 'ExceptionEventError';
        this.code = code
        this.response = response
    }
}


export const handleError = (error, alert, message) => {
    if(error instanceof NetworkError || error instanceof ExceptionEventError || error instanceof FetchError){
        // FetchError already handled in fetch.js
    }else{
        console.error(`Error - `, error)
        Sentry.captureException(error)
    }
}