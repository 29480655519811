export const COLORS = {
    white: '#FFFFFF',
    black: '#000',
    primary:'#183e62',
    layer_color:'rgba(4, 40, 68, 0.7)',
    button_primary:'#6666ff',
    button_danger:'#ff4d4d',
    main_page_button:'rgba(3,29,66,0.4)',
    carousel_background:'rgb(0,0,0,.6)',
    mainpage_locale:'#1ABB9C',
    grey:'#ced4da',
    light_grey:'#EFEFEF',
    // button_submit:'#6666ff',
    // button_ok:'#6666ff',
    // button_cancel:'#ff4d4d',
    // button_delete:'#ff4d4d',
    // button_inc:'#6666ff',
    // button_dec:'#ff4d4d',
    //background_toolbar:'', //it has background image 
    background_leftmenu:'#314456',
    background_panel:'#49657F',
    //background_Topbar:'#183e62',
    background_bottommenubar:'#333333',
    modal_heading:'#000000',
    // modal_heading:'#314456',
    // your colors
    theme_color: 'rgba(245, 246, 248, 1)'
  }