// const crypto = require('crypto');
// // const cryptoBrowserify = require('crypto-browserify');
// const format = require('biguint-format');

// const random_generator = (bytes) => {
//     const id = crypto.randomBytes(bytes ? bytes : 10);
//     return format(id, 'hex');
// }

function generate_random_string(){
    var random_string = "";
    var characters = "0123456789abcdef";
    for(var i = 0; i < 32; i++){
        random_string += characters[Math.floor(Math.random() * characters.length)];
    }
    return random_string;
}

export default generate_random_string