import React, { useState } from 'react';
import { Button, Input, Card, Col, Row } from "reactstrap";
import reactCSS from 'reactcss';
import {COLORS} from '../utils//color.js'
import { useAlert } from 'react-alert'
import general_fetch from '../utils/fetch';
import {useHistory } from 'react-router-dom';

const styles =  reactCSS({
    'default': {
        main_div:{
            // background: 'url("/resources/images/site_image.jpeg") 50% fixed',
            height: '100vh',
            width : '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '10px',
            // backgroundSize: 'cover',
            overflow: 'hidden'

        },
        layer: {
            width: '100%',
            height: '100%',
            position: 'absolute',
            left: '0',
            top: '0',
            background: COLORS.layer_color,
        },
        nav_bar: {
            background: COLORS.primary,
            color: COLORS.white,
            height:'55px',
            width:'100%',
        },
        infurnia_logo:{
            maxWidth: '120px',
            maxHeight: '30px',
            width: '100%',
            objectFit: 'contain'
        },
        nav_bar_icons: {
            lineHeight: '20px',
            marginBottom: '0px',
            padding:'8px',
            marginRight:'10px'
        },
        main_page_button: {
            color: COLORS.white,
            border: '2px solid #1ab',
            borderRadius: '25px',
            background: COLORS.main_page_button,
            fontSize: '14px',
            padding: '10px',
            width: '100%',
            textAlign: 'center',
            fontWeight: 'bold'
        },
        recent_designs_title: {
            color: COLORS.white,
            fontSize:'16px', 
            paddingLeft:'10px',
            width:'100%',
            textAlign:'left',
            borderLeft:'2px solid #1ABB9C',
            height:'20px',
            marginLeft:'15px'
        },
    },
});

const ResetPassword = ({org_key, reset_password_token, set_page_loader, org_key_logo, org_key_id, org_key_name}) => {

    const alert = useAlert()
    const history = useHistory();
    var SHA512 = require('js-sha512')

    const [ new_password, set_new_password ] = useState("");
    const [ confirm_password, set_confirm_password] = useState("");

    const onclick_reset_password = () =>{
        if(!new_password){
            alert.error('Please enter password')
        }else if (!confirm_password){
            alert.error('Please confirm password')
        }else if (confirm_password!=new_password){
            alert.error('Password mismatch')
        }else{
            reset_password();
        }

    }
    // {
    //     "new_password": "hellopass",
    //     "new_password_confirm": "hellopass"
    // }

    const reset_password = async () => {
        try{
            var body = { new_password:SHA512(new_password), new_password_confirm:SHA512(confirm_password) }
            var resp = await general_fetch({url:'/user/reset_password/'+reset_password_token, body, return_response_code:true})
            if(resp && resp.response_code == 1){ 
                alert.success('Password successfully updated')
                setTimeout(() =>{
                    // window.close();
                    var url = '/';
                    let  t = (org_key?(url = "/"+org_key + url):null);
                    history.push(url)
                    
                    set_new_password('')
                    set_confirm_password('')
                },3000);
            }else if(resp && resp.action === "TOAST_ERROR"){
               alert.error(resp.message)
            }
        }catch(err){
            alert.error('Token expired')
            console.log(err)
            var url = '/';
            let t = (org_key?(url = "/"+org_key + url):null);
            history.push(url)
        }
    }

    return(
        <div style={styles.main_div}>
            <div style={{backgroundColor:'inherit', textAlign:'center', marginBottom:'40px'}}><img  height='56px' src={org_key_logo} /></div>    
            <div style={{padding:'80px 80px 60px 80px', fontSize:'14px', border:'1px solid rgba(230, 233, 238, 1)', borderRadius:'14px', boxShadow:'8px 8px 20px 6px rgba(184, 191, 202, 0.14)', width:'425px', maxWidth:'99%'}}>
                                {/* <Card style={{boxShadow: '0 2px 5px 0 rgb(0 0 0), 0 2px 5px 0 rgb(0 0 0)'}}>
                        
                    <div style = {{ padding:'40px 25px 30px'}}> */}
                        
                        <Row style={{paddingBottom:'8px'}}>
                            <Col>
                                <div className="light_text" style={{textAlign:'left'}}>Password</div>
                            </Col>
                        </Row>
                        <Row style={{paddingBottom:'30px'}}>
                            <Col>
                                <Input value={new_password} onChange={(e) => set_new_password(e.target.value)} style={{width:'100%',height:'42px', fontSize:'12px', color:'#435A6F', backgroundColor: '#FFFFFF', borderRadius: '3px'}} placeholder='Password' fullWidth type='password'/>
                            </Col>
                        </Row>
                        <Row style={{paddingBottom:'8px'}}>
                            <Col>
                                <div className='light_text' style={{textAlign:'left'}}>Confirm Password</div>
                            </Col>
                        </Row>
                        <Row style={{paddingBottom:'40px'}}>
                            <Col>
                                <Input value={confirm_password} onChange={(e) => set_confirm_password(e.target.value)} style={{width:'100%',height:'42px', fontSize:'12px', color:'#435A6F', backgroundColor: '#FFFFFF', borderRadius: '3px'}} placeholder='Confirm Password' fullWidth type='password'/>
                            </Col>
                        </Row>
                        
                        {/* <Row>    
                            <Col style={{padding:'20px 0px 20px', textAlign:'center'}}>
                                <Button onClick={onclick_reset_password} className='blue_button' >Reset Password</Button>
                            </Col>
                        </Row> */}

                        <Row>
                            <Col style={{}}>
                                <Button  id="reset_password" type="button" className='btn_success' style={{ background:'linear-gradient(0deg, #213D5F, #213D5F)', textAlign:'center', border:0,borderRadius:'28px', width:'auto', lineHeight:'36px', paddingLeft:'32px', paddingRight:'32px' }}  onClick={onclick_reset_password} >Reset Password</Button>
                            </Col>
                        </Row>
                    {/* </div>
                </Card> */}
            </div>
        </div>
    );

}


export default ResetPassword;